import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonPage, IonRouterOutlet } from '@ionic/vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'TabsPage',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonTabs), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonRouterOutlet)),
          _createVNode(_unref(IonTabBar), { slot: "bottom" }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonTabButton), {
                tab: "tab1",
                href: "/tabs/home"
              }, {
                default: _withCtx(() => [
                  _cache[1] || (_cache[1] = _createElementVNode("svg", {
                    xmlns: "http://www.w3.org/2000/svg",
                    class: "w-6 h-6 icon icon-tabler icon-tabler-home-2",
                    width: "24",
                    height: "24",
                    viewBox: "0 0 24 24",
                    "stroke-width": "2",
                    stroke: "currentColor",
                    fill: "none",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }, [
                    _createElementVNode("path", {
                      stroke: "none",
                      d: "M0 0h24v24H0z",
                      fill: "none"
                    }),
                    _createElementVNode("polyline", { points: "5 12 3 12 12 3 21 12 19 12" }),
                    _createElementVNode("path", { d: "M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" }),
                    _createElementVNode("rect", {
                      x: "10",
                      y: "12",
                      width: "4",
                      height: "4"
                    })
                  ], -1)),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("Início")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                tab: "tab2",
                href: "/tabs/catalog"
              }, {
                default: _withCtx(() => [
                  _cache[3] || (_cache[3] = _createElementVNode("svg", {
                    xmlns: "http://www.w3.org/2000/svg",
                    class: "w-6 h-6 icon icon-tabler icon-tabler-list-search",
                    viewBox: "0 0 24 24",
                    "stroke-width": "2",
                    stroke: "currentColor",
                    fill: "none",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }, [
                    _createElementVNode("path", {
                      stroke: "none",
                      d: "M0 0h24v24H0z",
                      fill: "none"
                    }),
                    _createElementVNode("circle", {
                      cx: "15",
                      cy: "15",
                      r: "4"
                    }),
                    _createElementVNode("path", { d: "M18.5 18.5l2.5 2.5" }),
                    _createElementVNode("path", { d: "M4 6h16" }),
                    _createElementVNode("path", { d: "M4 12h4" }),
                    _createElementVNode("path", { d: "M4 18h4" })
                  ], -1)),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Catálogo")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                tab: "tab3",
                href: "/tabs/dictionary"
              }, {
                default: _withCtx(() => [
                  _cache[5] || (_cache[5] = _createElementVNode("svg", {
                    xmlns: "http://www.w3.org/2000/svg",
                    class: "w-6 h-6 icon icon-tabler icon-tabler-vocabulary",
                    viewBox: "0 0 24 24",
                    "stroke-width": "2",
                    stroke: "currentColor",
                    fill: "none",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }, [
                    _createElementVNode("path", {
                      stroke: "none",
                      d: "M0 0h24v24H0z",
                      fill: "none"
                    }),
                    _createElementVNode("path", { d: "M10 19h-6a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1h6a2 2 0 0 1 2 2a2 2 0 0 1 2 -2h6a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-6a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2z" }),
                    _createElementVNode("path", { d: "M12 5v16" }),
                    _createElementVNode("path", { d: "M7 7h1" }),
                    _createElementVNode("path", { d: "M7 11h1" }),
                    _createElementVNode("path", { d: "M16 7h1" }),
                    _createElementVNode("path", { d: "M16 11h1" }),
                    _createElementVNode("path", { d: "M16 15h1" })
                  ], -1)),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("Dicionário")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                tab: "tab4",
                href: "/tabs/about"
              }, {
                default: _withCtx(() => [
                  _cache[7] || (_cache[7] = _createElementVNode("svg", {
                    xmlns: "http://www.w3.org/2000/svg",
                    class: "w-6 h-6 icon icon-tabler icon-tabler-info-square-rounded",
                    width: "24",
                    height: "24",
                    viewBox: "0 0 24 24",
                    "stroke-width": "2",
                    stroke: "currentColor",
                    fill: "none",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }, [
                    _createElementVNode("path", {
                      stroke: "none",
                      d: "M0 0h24v24H0z",
                      fill: "none"
                    }),
                    _createElementVNode("path", { d: "M12 9h.01" }),
                    _createElementVNode("path", { d: "M11 12h1v4h1" }),
                    _createElementVNode("path", { d: "M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" })
                  ], -1)),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("Sobre")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})